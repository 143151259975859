import { request } from 'boot/axios'
import { getformatQuery } from 'utils/tools'
// 登录接口
export function login(data) {
  return request({ url: '/v1/login/login', method: 'post', data })
}
// 验证是否是虚拟号码
export function checkIfVistualTel(data) {
  return request({ url: '/v1/login/virtualPhone', method: 'post', data })
}
// 注册
export async function register(data) {
  return request({ url: '/v1/login/mainUserRegister', method: 'post', data })
}
// 被邀请用户注册
export async function inviteUserRegister(data) {
  return request({ url: '/v1/login/inviteUserRegister', method: 'post', data })
}

// 邀请码校验接口
export function enterprise(code) {
  return request({ url: '/v1/sysCompany/verificationInvitationCode/' + code, method: 'get' })
}

// 注册发送短信验证码
export function sendSmsForRegister(data) {
  return request({ url: '/v1/login/sendSmsForRegister', method: 'post', data })
}

// 重置密码
export async function modifyPwd(data) {
  return request({ url: '/v1/login/modifyPwd', method: 'post', data })
}

// 重置密码获取验证码
export function sendSmsForResetPwd(data) {
  return request({ url: '/v1/login/sendSmsForResetPwd', method: 'post', data })
}
// 手机号认证
export function phoneAuthenticated(data) {
  return request({ url: '/v1/login/phoneAuthenticated', method: 'post', data })
}
// 用户列表
export function getUserList(data) {
  return request({ url: '/v1/sysUsers/list', method: 'post', data })
}

// 无分页子账号列表
export function getChildUserListNp() {
  return request({ url: '/v1/sysUsers/getChildUseid', method: 'post' })
}

// 用户停用
export function disactiveUser(data) {
  return request({ url: '/v1/sysUsers/updateStatusStop', method: 'post', data })
}

// 用户启用
export function recoverUser(data) {
  return request({ url: '/v1/sysUsers/recover', method: 'post', data })
}

// 员工管理-修改用户密码
export function updateUserPwd(data) {
  return request({ url: '/v1/sysUsers/updateUserPwd', method: 'post', data: data })
}

// 添加用户
export function addUser(data) {
  return request({ url: '/v1/sysUsers', method: 'post', data })
}

// 修改用户
export function editUser(data) {
  return request({ url: '/v1/sysUsers', method: 'put', data: data })
}

//删除用户
export function deleteUser(ids) {
  return request({ url: '/v1/sysUsers', method: 'delete', data: ids })
}

// 用户详情
export function getUserDetail(id) {
  return request({ url: '/v1/sysUsers/' + id, method: 'get' })
}

// 当前登录用户信息
export function getUserInfo() {
  return request({ url: '/v1/sysUsers/currentUser', method: 'get' })
}

// 个人中心修改email and qq
export function updateCurrentUserInfo(data) {
  return request({ url: '/v1/sysUsers', method: 'put', data: data })
}
// 个人中心绑定email
export function bindEmail(data) {
  return request({ url: '/v1/login/bindEmail', method: 'post', data })
}

// 个人中心修改密码
export function resetCurrentUserPwd(data) {
  return request({ url: '/v1/login/resetPwd', method: 'post', data })
}

// 个人中心修改手机号
export function updateCurrentUserPhone(data) {
  return request({ url: '/v1/login/modifyPhone  ', method: 'post', data })
}

// 个人中心修改手机号获取验证码
export function sendSms(data) {
  return request({ url: `/v1/login/modifyPhoneSendMessage`, method: 'post', data })
}

// checkPhone  修改手机号时验证此手机号是否绑定其他用户
export function checkPhoneOfUser(data) {
  return request({ url: `/v1/login/checkPhone`, method: 'post', data })
}
// 获取登录验证码
export function sendSmsForLogin(data) {
  return request({ url: `/v1/login/sendSmsForLogin`, method: 'post', data })
}
// 退出登录
export function userLogout() {
  return request({ url: '/v1/login/logout', method: 'get' })
}

// 打开
export function openRequestUrl(url) {
  return request({
    url: url,
    method: 'get'
  })
}

// 心跳
export function heartbeatFn(data, headers) {
  return request({ url: '/v1/sysUsers/softHeartBeat', method: 'post', data, headers })
}

// OEM信息
export function getOemInfo(data) {
  return request({ url: '/v1/login/getOemInfo', method: 'post', data })
}

// 系统通知
export function getExpiredTips() {
  return request({ url: '/v1/softmessage', method: 'post', data: {} })
}

// 实名认证，获取支付宝二维码地址
export function getRealnameAuthUrl(data) {
  return request({ url: '/v1/sysCompany/authUser', method: 'post', data })
}
// 企业认证，获取支付宝二维码
export function authLegalPerson(data) {
  return request({ url: '/v1/sysCompany/authLegalPerson', method: 'post', data })
}
// 查询用户实名认证状态
export function checkRealnameAuth() {
  return request({ url: '/v1/sysCompany/getAuthUser', method: 'get' })
}
// 企业认证验证实名
export function getLegalPersonAuthStatus() {
  return request({ url: '/v1/sysCompany/getLegalPersonAuthStatus', method: 'get' })
}
// 验证实名状态
export function getAuthUserStatus(params) {
  return request({ url: '/v1/sysCompany/getAuthUserStatus', method: 'get', params })
}

// 获取官方群二维码
export function getContactGroupQr() {
  return request({ url: '/v1/sysUsers/getSoftGroup', method: 'post' })
}

// 找回用户名
export function getUserNameByPhone(data) {
  return request({ url: '/v1/login/getUserNameByPhone', method: 'post', data })
}
// 确认密码
export function checkUserPass(data) {
  return request({ url: '/v1/sysUsers/checkUserPass', method: 'post', data })
}

// 找回用户名发送验证码
export function sendSmsForUsername(data) {
  return request({ url: '/v1/login/sendSmsForUsername', method: 'post', data })
}

// 获取客服二维码
export function getKefuImg() {
  return request({ url: '/users/getSoftKefu', method: 'post' })
}

// 手动退出软件
export function appQuitManually() {
  return request({ url: '/v1/sysUsers/closeSoft', method: 'get' })
}

// 通知
export function getNotifys(data) {
  return request({ url: '/v1/sysNotice/list', method: 'post', data })
}

export function getTopNotice() {
  return request({ url: '/v1/sysNotice/getTop', method: 'get' })
}

// 领取8折优惠券
export function getPromotionCouponForUser() {
  return request({ url: '/v1/sysUserCoupon/promotionCoupon', method: 'post' })
}

// 设置语言
export function setLanguage(lang) {
  return request({ url: '/v1/sysUsers/setLanguage', method: 'post', data: lang })
}

// 获取版本信息
export function getReleaseNotes(version) {
  return request({ url: '/v1/sysVersions/getContent', method: 'post', data: version })
}

// 自动续费
export function switchAutoRenew(data) {
  return request({ url: '/v1/sysUsers/autoRenew', method: 'post', data: { autoRenew: data } })
}

// 支付通道列表
export function getPaymentChannelList() {
  return request({
    url: '/v1/sysPayChannel/list',
    method: 'post',
    data: {}
  })
}

// 开启/关闭双重验证
export function switch2faStatus(data) {
  return request({ url: '/v1/sysUsers/setSecendAuthStatus', method: 'post', data })
}

// google 验证器密钥
export function get2faSecretKey() {
  return request({ url: '/v1/sysUsers/getGoogleAuthenticator', method: 'post' })
}

// 绑定Google验证器
export function bindAuthenticator(data) {
  return request({ url: '/v1/sysUsers/setSecondAuthenticator', method: 'post', data })
}

// 解绑Google验证器
export function unbindAuthenticator(data) {
  return request({ url: '/v1/sysUsers/closeSecondAuthenticator', method: 'post', data })
}

// 双重验证发送短信
export function send2faSms(data) {
  return request({ url: '/v1/login/sendSmsSecondAuth', method: 'post', data })
}

// 登录时双重验证发短信
export function send2faSmsOnLogin(data) {
  return request({ url: '/v1/login/loginSecondAuthBySms', method: 'post', data })
}
//设置是否展示日志
export function updateViewLog(data) {
  return request({ url: '/v1/sysUsers/updateViewLog', method: 'post', data })
}
// 通过Email发送验证码
export function getCodeFromEMail(obj) {
  let str = getformatQuery(obj)
  return request({ url: '/v1/login/getCodeFromEMail?' + str, method: 'get' })
}
// 校验邮箱是否可以绑定
export function checkEmail(data) {
  return request({ url: `/v1/login/checkEmail`, method: 'post', data })
}
// 是否开启彻底删除窗口密码验证
export function isCheckPwd(data) {
  return request({ url: '/v1/sysUsers/isCheckPwd', method: 'post', data })
}
// 是否开启转移店铺密码验证
export function transferCheckPwd(data) {
  return request({ url: '/v1/sysUsers/transferCheckPwd', method: 'post', data })
}
// 登录请求授权
export function applyForAuthorization(data) {
  return request({ url: '/v1/authorizedApproval/applyForAuthorization', method: 'post', data })
}
// 编辑企业认证是否7天不提醒
export function modifyCertificationRemind(flag) {
  return request({ url: '/v1/sysCompany/modifyCertificationRemind?flag=' + flag, method: 'get' })
}
// 获取滑块验证码
export function getVerification(obj) {
  let str = getformatQuery(obj)
  return request({ url: '/v1/login/getVerification?' + str, method: 'get' })
}
// 校验滑块验证码
export function checkVerificationImage(obj) {
  let str = getformatQuery(obj)
  return request({ url: '/v1/login/checkVerificationImage?' + str, method: 'get' })
}
// 关闭公司认证提醒
export function closeFailureReminder(obj) {
  return request({ url: '/v1/sysCompany/closeFailureReminder', method: 'get', params: { ...obj } })
}
// 校验登陆滑块验证码
export function checkVerificationImg(data) {
  return request({ url: '/v1/login/checkVerificationImg', data, method: 'post' })
}
// 获取登陆验证码
export function getValidationImg(data) {
  return request({ url: '/v1/login/getValidationImg', data, method: 'post' })
}
// 检测是否要弹滑块验证
export function getRestrictionStatus(params) {
  return request({ url: '/v1/login/getRestrictionStatus', method: 'post', params })
}
// 查询经办人认证状态
export function getCompanyCertificationInfo() {
  return request({ url: '/v1/sysCompany/getCompanyCertificationInfo', method: 'get' })
}
// 企业四要素核身认证接口
export function enterpriseCertification4(data) {
  return request({ url: '/v1/sysCompany/enterpriseCertification4', method: 'post', data })
}
// 获取企业对公打款账户信息
export function initiateReversePayment() {
  return request({ url: '/v1/sysCompany/initiateReversePayment', method: 'get' })
}
// 发起授权签署并获取签署链接接口
export function initiateAuthorizationSigning(data) {
  return request({ url: '/v1/sysCompany/initiateAuthorizationSigning', method: 'post', data })
}
// 获取签署状态接口
export function getAuthorizationSigningStatus() {
  return request({ url: '/v1/sysCompany/getAuthorizationSigningStatus', method: 'get' })
}
// 查询反向打款进度接口
export function queryReversePaymentSchedule() {
  return request({ url: '/v1/sysCompany/queryReversePaymentSchedule', method: 'get' })
}
// 登录时候验证一个手机号有几个用户
export function getUserInfosByTelphone(params) {
  return request({ url: '/v1/login/getUserInfosByTelphone', method: 'get', params })
}
// 企业认证发送短信
export function sendMsmToLegalPerson(data) {
  return request({ url: '/v1/sysCompany/sendMsmToLegalPerson', method: 'post', data })
}
// 关闭注册送好礼的接口
export function closeClaimCouponsWindow() {
  return request({ url: '/v1/sysUsers/closeClaimCouponsWindow', method: 'post' })
}
// 6个月没改密码，让后端记录时间
export function updatePasswordTime() {
  return request({ url: '/v1/sysUsers/passwordModificationTime', method: 'post' })
}
export function personalCenterPwd(data) {
  return request({ url: '/v1/login/forceModifyPwd', method: 'post', data })
}

// 搜集用户退出错误
export function getUserApiErrorLog() {
  return request({ url: '/v1/sysUsers/getUserApiErrorLog', method: 'get' })
}
// 已知晓退出错误
export function clearApiErrorLog() {
  return request({ url: '/v1/sysUsers/clearApiErrorLog', method: 'get' })
}
