/**
 * @description 登录、获取用户信息、退出登录、清除token逻辑，不建议修改
 */
import { getUserInfo, login, userLogout, getKefuImg } from 'api/user'
import { agentOwnTypeList } from 'api/agent'
import { getToken, removeToken, setLocalToken, TOKEN_KEY } from 'utils/token'
import router, { resetRouter } from 'src/router'
import { isString } from 'utils/validate'

import { Notify, LocalStorage, SessionStorage } from 'quasar'

const state = () => ({
  token: getToken(),
  username: '',
  avatar: 'https://i.gtimg.cn/club/item/face/img/9/15919_100.gif',
  balance: 0, // 余额
  userType: null, //用户身份1，2，3，4
  roleId: null, // 角色ID
  userInfo: {}, // 完整的userInfo对象
  browserInfoNumVo: {}, // 设备相关的数据
  tipMsg: '', // 到期提醒
  kefuImg: '', // 客服二维码
  certification: 0, //首页认证弹窗
  owntype: {}, // 设备平台列表
  syncTasks: new Map() // {seq: Set([...taskId])}
})
const getters = {
  token: state => state.token,
  username: state => state.username,
  avatar: state => state.avatar,
  balance: state => state.balance,
  userType: state => state.userType,
  roleId: state => state.roleId,
  userInfo: state => state.userInfo,
  browserInfoNumVo: state => state.browserInfoNumVo,
  tipMsg: state => state.tipMsg,
  isMainAccount: state => state.userType === 1,
  kefuImg: state => state.kefuImg,
  permissions: state => state.userInfo.menuCodes || [],
  syncTasks: state => Array.from(state.syncTasks.keys()),

  certification: state => state.certification,
  owntype: state => state.owntype
}
const mutations = {
  SET_TOKEN(state, token) {
    state.token = token
    setLocalToken(token)
  },
  SET_USERNAME(state, username) {
    state.username = username
  },
  SET_AVATAR(state, avatar) {
    state.avatar = avatar
  },
  SET_BALANCE(state, balance) {
    state.balance = balance
  },
  SET_ROLEID(state, roleId) {
    state.roleId = roleId
  },
  SET_USER_TYPE(state, type) {
    state.userType = type
  },
  SET_USERINFO(state, info) {
    state.userInfo = info
  },
  SET_TIP_MSG(state, msg) {
    state.tipMsg = msg
  },
  SET_KEFU(state, kefuImg) {
    state.kefuImg = kefuImg
  },
  SET_CERTIFICATION(state, flag) {
    state.certification = flag
  },
  SET_OWNTYPE(state, flag) {
    state.owntype = flag
  },
  SET_AGENTINFO(state, flag) {
    state.browserInfoNumVo = flag
  },
  // 监听task sync事件
  SET_SYNC_TASK(state, task) {
    if (task.type === 'add') {
      if (state.syncTasks.has(task.seq)) {
        state.syncTasks.get(task.seq).add(task.taskId)
      } else {
        state.syncTasks.set(task.seq, new Set([task.taskId]))
      }
    } else {
      const itemSet = state.syncTasks.get(task.seq)
      itemSet.delete(task.taskId)
      if (!itemSet.size) state.syncTasks.delete(task.seq)
    }
  },
  // 刷新后获取所有tasks
  ALL_SYNC_TASKS(state, tasks) {
    if (!tasks || !tasks.length) {
      state.syncTasks.clear()
    } else {
      for (let item of tasks) {
        this.commit('user/SET_SYNC_TASK', { type: 'add', ...item })
      }
    }
  }
}
const actions = {
  async login({ commit, rootGetters }, userInfo) {
    const data = await login(userInfo)
    const token = data.token
    const status = data.status
    commit('SET_CERTIFICATION', status)
    LocalStorage.set('loginApiErrorLog', 1)
    if (token) {
      commit('SET_TOKEN', token)
      router.replace('/')
    } else if ([1, 2, 3].includes(data.status) || data.secondAuth === 1) {
      return data
    } else {
      const err = `登录接口异常，未正确返回${TOKEN_KEY}...`
      throw new Error(err)
    }
  },
  /**
   * 获取设备平台
   */
  async getDecvice({ commit, dispatch, state }) {
    const data = await agentOwnTypeList()
    let obj = {}
    data.forEach(item => {
      obj[item.id] = item
    })
    commit('SET_OWNTYPE', obj)
  },
  /**
   * @description 获取用户信息接口 这个接口非常非常重要，如果没有明确底层前逻辑禁止修改此方法，错误的修改可能造成整个框架无法正常使用
   * @param {*} { commit, dispatch, state }
   * @returns
   */
  async getUserInfo({ commit, dispatch, state }) {
    const data = await getUserInfo()
    let { userName, avatar, roleId, userType, balance, tipMsg, browserInfoNumVo } = data

    // 保存整个userInfo
    commit('SET_USERINFO', data)
    // 设备，店铺，相关信息
    commit('SET_AGENTINFO', browserInfoNumVo)
    // 保存余额
    commit('SET_BALANCE', balance)
    const username = userName

    if ((username && !isString(username)) || (avatar && !isString(avatar))) {
      const err = 'getUserInfo核心接口异常，请检查返回JSON格式是否正确'
      throw err
    } else {
      if (username) commit('SET_USERNAME', username)
      if (avatar) commit('SET_AVATAR', avatar)
      if (roleId) commit('SET_ROLEID', roleId)
      if (userType) commit('SET_USER_TYPE', userType)

      commit('SET_TIP_MSG', tipMsg || '')
    }
  },
  // 检查下是否存在userInfo，不存在则请求一次
  async checkUserInfo({ state, dispatch }) {
    if (!state.userInfo.id) {
      await dispatch('getUserInfo')
      // 兼容下cookie的token，未重新登录的用户需要同步设置下cookie
      const token = getToken()
      if (token) {
        setLocalToken(token)
      }
    }
  },
  async resetAll({ commit }) {
    commit('SET_TOKEN', '')
    commit('SET_USERNAME', '')
    commit('SET_BALANCE', 0)
    commit('SET_USER_TYPE', null)
    commit('SET_ROLEID', null)
    commit('SET_USERINFO', {})
    commit('SET_AGENTINFO', {})
    resetRouter()
    removeToken()
    LocalStorage.remove('closeNotice')
    SessionStorage.remove('registerGift')
    LocalStorage.remove('enterpriseCertification')
    commit('routes/setRoutes', [], { root: true })
    router.replace({ name: 'Login' })
  },
  setToken({ commit }, token) {
    commit('SET_TOKEN', token)
  },
  setAvatar({ commit }, avatar) {
    commit('SET_AVATAR', avatar)
  },
  // 弃用
  getKefuImg({ state, commit }) {
    // 当前有客服图片时，不重新获取
    if (!!state.kefuImg) return
    getKefuImg().then(res => {
      if (res && res.code === 1) {
        commit('SET_KEFU', res.weixinimage)
      }
    })
  }
}
export default { state, getters, mutations, actions }
