export default {
  unbindDevice: '确定要解绑 {arg1} 绑定的IP设备吗?',
  unbindDeviceTip: '当前所选专线数量{arg1}个',
  unbindDeviceTip1: '确定解绑所选专线已绑定的IP设备吗？',
  deleteCompletelyConfirm: '确定彻底删除 {arg1} 专线吗？<p class="text-red">专线删除后不可恢复，请谨慎操作！</p>',
  deleteCompletelyConfirm1: '确定彻底删除所选的{arg1}个专线吗？<p class="text-red">专线删除后不可恢复，请谨慎操作！</p>',
  unbindCrossTip: '确定要解绑 {arg1} 绑定的专线吗?',
  buyAgentLimit: '实名认证成功后，专线购买数量不受限制！',
  buyAgentLimit1: '购买专线限制',
  batchUnbind: '批量解绑',
  deleteCross: '删除跨境专线',
  bindIp: '绑定IP设备',
  unbindIp: '解绑IP设备',
  name: '专线名称',
  bindIpName: '绑定设备名称',
  status: '状态',
  pleaseSelectCross: '请选择跨境专线',
  buy: '购买专线',
  pleaseInputName: '请输入专线名称',
  agentIpName: 'IP设备名称',
  crossType: '专线类型',
  crossLine: '跨境专线',
  viewCross: '查看专线',
  crossPrice: '专线价格',
  selectCrossTitle: '选择要绑定的专线',
  selectCross: '已选专线',
  crossRenew: '专线续费',
  currentCross: '当前专线：',
  bindCross: '绑定专线',
  unbindCross: '解绑专线',
  bindCrossTip: '已绑定跨境专线',
  unbindCrossTip: '未绑定跨境专线',
  buyTip:
    '火豹跨境专线，提供广州到香港的专线带宽服务，可以很大程度的提升海外IP设备的网速、网络稳定性，建议使用海外IP设备时，购买该服务并绑定到相应的IP设备上！ <div class="text-red">注意：仅支持绑定海外的平台IP设备，大陆的平台IP设备和自有IP设备无法绑定！</div>',
  buyTip1: '平台中所有海外IP设备默认包含跨境网络加速功能，极大提升海外IP网速及网络稳定性！使用时，无需本地开代理！',
  buyCross: '购买跨境专线',
  renewalTip2: '开启自动续费专线个数',
  renewalTip3: '取消自动续费专线个数',
  renewalTip4: '1、专线到期前3日执行自动续费，届时续费金额将从账户余额中扣除，请保证您的账户余额充足；',
  renewalTip5: '2、若余额不足导致续费失败，未续费的专线到期后将被永久注销，不支持找回。',
  renewalTip6: '取消已选中专线的自动续费，将不再进行自动续费操作！您确定取消吗？',
  deviceExpirationDate: '专线到期日期',
  noBind: '暂未绑定'
}
