export default {
  seq: 'Serial No.',
  copyID: 'Copy ID',
  baseInfo: 'Basic',
  proxyInfo: 'Proxy',
  preferenceInfo: 'Preference',
  fingerprintInfo: 'Fingerprint',
  clonePlaceholder: 'Enter the number of clones',
  batchAddPlaceholder: 'Enter the number of batch add',
  numberOfClones: 'Quantity',
  numberOfAdd: 'Quantity',
  cloneNumTips: 'Number of clones of this browser.',
  batchAddNumTips: 'Batch add this number of browsers at once.',
  genFingerprintType: 'Fingerprint Generation Method',
  randomFingerprint: 'Random',
  cloneFingerprint: 'Clone',
  randomFpTips: 'Randomly generate useragent and fingerprint properties.',
  cloneFpTips: 'The useragent and fingerprint properties of the current browser will be copied.',
  namePlaceholder: 'The prefix of the copied browser names',
  namePrefix: 'Browser Name Prefix',
  browserName: 'Browser Name',
  batchAddNameTips: 'Name the browser, which will be combined with the serial No. to generate the final name, for example: browser_1234',
  addNameTips: 'Name the browser to facilitate future management!',
  group: 'Group',
  groupSelect: 'Group',
  groupPlaceholder: 'Filter by Tag name',
  groupSelectTips:
    'Note: If you select a group under another account, the browser will be transferred to the group of other accounts (the ownership of the browser will belong to other accounts).',
  noGroup: 'Ungroup',
  duplicateValidation: 'Duplicate Validation',
  validByUsername: 'Verify by username and password',
  validByUsernameTips:
    'Verification will happen when Account platform, user name, and password are not null, and will be compared with all past data. Only when the above three are exactly the same then will judged as a duplicate and the browser will never be created!',
  usernamePlaceholder: 'Set platform login username',
  platform: 'Platform',
  platformAuth: 'Auth',
  username: 'Username',
  usernameTips: 'Autofill username while browser opened.',
  passwordPlaceholder: 'Set platform login password',
  password: 'Password',
  passwordTips: 'Autofill password while browser opened.',
  remarkPlaceholder: 'Enter the browser remarks',
  remark: 'Remark',
  cookiePlaceholder: 'Optional, support for array with json items, such as {demo}',
  paste: 'Paste',
  cookieTips: 'Enter the valid cookies',
  parseCookies: 'Parse Cookies',
  otherCookies: 'Other Cookies',
  addOtherCookies: 'Add Other Cookie',
  otherCookiesPlaceholder: 'Other cookies can be added several times',
  add: 'Add',
  randomAll: 'Random Fingerprint',
  addRuleMsg: 'A maximum of 1 - 300 stores can be created in batches at a time.',
  cloneRuleMsg: 'Up to 1-300 stores can be cloned at once.',
  nameRuleMsg: 'Browser name up to 50 characters.',
  groupRuleMsg: 'Please select group',
  remarkRuleMsg: 'Remarks up to 500 characters',
  usernameRuleMsg: 'Username up to 100 characters',
  passwordRuleMsg: 'Password up to 100 characters',
  toGroupConfirm: 'Confirm redirect to Group?<br/>Please note that the filled information will be lost after the redirect.',
  editSuccess: 'Success',
  addSuccess: 'Success',
  validationErrMsg: 'Validation Failed',
  pasteError: 'Paste error, please paste manually.',
  parseCookiesFailed: 'Parse cookies failed.',
  copySuccess: 'Success',
  coreVersion: 'Kernel Version',
  coreVersionTips:
    'It is recommended to select the latest kernel. If switching between different kernels, clear the cache to avoid exceptions',
  coreVersionTips2: '2.4.1 and above versions are valid.',
  ostype: 'Device',
  pcTips: 'Simulate a PC-side environment, and generate a PC-side useragent at the same time.',
  androidTips: 'Simulate a android browser environment, and generate a android useragent at the same time.',
  iosTips: 'Simulate a ios browser environment, and generate a ios useragent at the same time.',
  osPlaceholder: 'Select OS',
  os: 'OS',
  osTips: 'Navigator platform value',
  versionPlaceholder: 'Select browser version',
  version: 'Browser Version',
  uaPlaceholder: 'Suggest automatic generation.',
  changeBtn: 'Change',
  language: 'Language',
  languageTips: 'Generate the corresponding language based on IP. Uncheck to customize.',
  checkLanguage: 'Select language',
  displayLanguage: 'Display Language',
  displayLanguageTips: 'Generate the corresponding display language based on IP. Uncheck to customize.',
  checkDisplayLanguage: 'Select Display Language',
  displayLanguageValueTips: 'Follow the system by default',
  timezone: 'Time Zone',
  timezoneTips: 'Generate the corresponding time zone based on IP. Uncheck to customize.',
  checkTimezone: 'Select Timezone',
  webrtcTips0: 'Enable WebRTC. The public IP will be replace by the proxy IP, and local IP will be masked.',
  webrtcTips1: 'Enable WebRTC. Your real IP address will be used.',
  webrtcTips2: 'Disable WebRTC. Website will detect that the WebRTC is disabled.',
  ignoreHttps: 'Ignore HTTPS Certificate Errors',
  ignoreHttpsTips: 'Ignore HTTPS certificate errors and open the webpage directly',
  position: 'Geolocation',
  positionTips0:
    'The website will display a prompt to ask for your current location, you can allow or prohibit it, just like the prompt of a normal browser.',
  positionTips1: 'Websites are always allowed to request your current location.',
  positionTips2: 'Websites are always blocked from requesting your current location.',
  positionValueTips: 'Generate the corresponding geolocation based on IP. Uncheck to customize.',
  lngPlaceholder: 'Enter Longitude',
  lng: 'Longitude',
  latPlaceholder: 'Enter Latitude',
  lat: 'Latitude',
  precisionPlaceholder: 'Enter precision',
  precision: 'Precision(meter)',
  windowSize: 'Window Size',
  openWidth: 'Open Width',
  openHeight: 'Open Height',
  windowSizeTips: 'Set the size of the browser window when it opens.',
  resolution: 'Resolution',
  windowSizeLimit: 'The max size of the window cannot exceed the resolution',
  pixelRatio: 'Pixel Ratio',
  fonts: 'Fonts',
  edit: 'Edit',
  canvasTips0: 'Each browser will generate a different Canvas.',
  canvasTips1: 'Each browser uses the default Canvas of the current computer.',
  webgl: 'WebGL Image',
  webglTips0: 'Each browser will generate a different WebGL.',
  webglTips1: 'Each browser uses the default WebGL of the current computer.',
  webglMeta: 'WebGL Meta',
  webglVender: 'Unmasked Vendor',
  webglRender: 'Unmasked Renderer',
  audioTips0: 'Each browser will generate a different Audio.',
  audioTips1: 'Each browser uses the default Audio of the current computer.',
  mediaDevice: 'Media Device',
  mediaDeviceTips0: 'Replace your real media device ID with a appropriate value.',
  mediaDeviceTips1: 'Each browser uses the default media device ID of the current computer.',
  speechTips0: 'Replace your real Speech Voices with a appropriate value.',
  speechTips1: 'Each browser uses the default Speech Voices of the current computer.',
  doNotTrackTips: 'Set whether personal information can be tracked by the website.',
  clientRectTips0: 'Replace your real ClientRects with appropriate values.',
  clientRectTips1: 'Each browser uses the default ClientRects of the current computer.',
  deviceInfo: 'Device Info',
  deviceInfoTips0: 'Replace your real device name and MAC address with appropriate values.',
  deviceInfoTips1: 'Each browser uses the default device name and MAC address of the current computer.',
  computerNamePlaceholder: 'Custom device name',
  computerName: 'Name',
  macAddrPlaceholder: 'Enter MAC address',
  macAddr: 'MAC Address',
  portScan: 'Port Scan Protection',
  portScanTips: 'Enable to prevent websites from scanning your local service port.',
  portWhiteList: 'Port Sacn White List',
  portWhiteListTips: 'Ports that are allowed to be scanned by websites, separated by commas',
  portWhiteListPlaceholder: 'Enter integers separated by commas',
  ssl: 'SSL',
  sslTips: 'Disabling some SSL features may cause some URLs to fail to open.',
  disableSSL: 'Disable SSL Features',
  plugins: 'Plugins',
  pluginsTips: 'Customized plugins may affect real plugin functions.',
  pluginsInfo: 'Plugins Info',
  pluginsDetails: 'Plugins ({num})',
  cpu: 'Hardware Concurrency',
  cpuUnit: 'Cores',
  cpuTips: 'Set CPU cores in the current browser environment.',
  deviceMemory: 'Device Memory',
  deviceMemoryTips: 'Set the memory of the current browser environment.',
  customizeFonts: 'Custom Fonts',
  fontPlaceholder: 'Filter by font name',
  customizePlugins: 'Custom Plugin',
  addPlugin: 'Add',
  editPlugin: 'Edit',
  reset2Default: 'Reset To Default',
  pluginName: 'Plugin Name',
  pluginDesc: 'Plugin Desctiption',
  pluginFilename: 'Filename',
  pluginMime: 'Mime',
  pluginSuffixes: 'Suffixes',
  lngRuleMsg: 'Please enter the accuracy value, with a maximum of 6 decimal places.',
  latRuleMsg: 'Please enter the latitude value, with a maximum of 6 decimal places.',
  precisionRuleMsg: 'Please enter the accuracy value within 3~5000 meters',
  languageRuleMsg: 'Select language',
  timezoneRuleMsg: 'Select time zone',
  portWhiteListRuleMsg: 'Please enter valid ports.',
  sslRuleMsg: 'Please select at least one feature.',
  pluginNameRuleMsg: 'Plugin name is required.',
  pluginDescRuleMsg: 'Plugin description is required.',
  pluginFilenameRuleMsg: 'Filename is required.',
  pluginMimeRuleMsg: 'Mime type is required.',
  pluginMimeDescRuleMsg: 'Mime description is requried.',
  pluginSuffixesRuleMsg: 'Suffixes is required.',
  setSuccess: 'Success',
  switchCoreVersionMsg:
    'Note: If you experience abnormal functionality in the browser store after switching browser engines, please clear the cache of that store and try again!',
  clearCacheAndSave: 'Clear Cache And Save',
  cancelSwitchAndSave: 'Save Without Switch Kernel',
  batchUpdateConfig: 'Batch Update Browser Properties',
  batchUpdateTips:
    'Please check the leftmost checkbox which is what you want to update, and modify the configuration. Then the properties of all browsers will be modified at the same time!',
  enable: 'Enable',
  disable: 'Disable',
  replace: 'Replace',
  allow: 'Allow',
  forbidden: 'Block',
  default: 'System Default',
  customize: 'Custom',
  random: 'Random',
  randomValue: 'Noise',
  followComputer: 'Follow Computer',
  ask: 'Ask',
  urlPlaceholder: 'URL prefix: http://, https:// or chrome-extension://',
  urlPlaceholder1: 'Enter a custom platform domain name',
  otherUrl: 'Open Url',
  urlTips: 'Setting your frequently used URLs here will display them on the browser workspace page for quick access!',
  urlRule1: 'URL length cannot exceed 500 characters',
  urlRule2: 'Please enter a valid URL.',
  urlRule3: 'URL already exists.',
  urlRule4: 'Please enter a URL first.',
  urlRule5: 'The number of URLs has exceeded the maximum limit.',
  urlRule6: 'The platform does not support HTTP type domain names. Please enter an https domain name!',
  platformPlaceholder: 'Select the account platform and the bottom "Other Platforms" can be customized',
  platformOthers: 'Other Platforms',
  editOtherUrl: 'Edit Url',
  platformOtherTips:
    'Please select the account platform or "Other Platforms" to set the URL. Opening the browser will open the account platform by default and the username and password set below will be automatically filled in the login page of the platform.',
  platformUrl: 'Platform URL',
  platformUrlPlaceholder: 'URL prefix: http://, https:// or chrome-extension://, leave blank to open nothing',
  platformRule1: 'Please select platform',
  platformRule2: 'Platform address URL cannot exceed 100 characters.',
  platformRule3: 'Please a valid url.',
  editUrl: 'Change the URL',
  osVersion: 'OS Version',
  enterOsVersion: 'Enter OS Version',
  secretKeyRuleMsg1: 'The key consists of numbers or letters',
  secretKeyRuleMsg2: 'The key can contain up to 100 characters',
  privacy: 'Privacy',
  privacyTips: 'Enable WebRTC but disable IP retrieval',
  multipleUrlPlaceholder:
    '1. URL prefix: http://, https:// or chrome-extension://;\n2. For multiple URLs, use "enter" to break each line;\n3. Click the "+" button on the right after entering all the URLs.'
}
