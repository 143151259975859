export default {
  balance: 'Balance',
  recharge: 'Recharge',
  coupon: 'Coupon',
  bill: 'Monthly Bill Fee',
  month: 'Month',
  days: ' Days Left',
  renew: 'Renew',
  expiredDays: '',
  expire: ' expires',
  freePackage: 'Free Package',
  noExpired: 'Permanently Available',
  billDetail: 'Billing Details',
  pkgLabel: 'Browser Profiles: ',
  users: 'Users:',
  additionUsers: 'Additional Users:',
  activate: 'Subscribe',
  change: 'Change Plan',
  changeUsers: 'Change Users',
  statistics: 'Statistics',
  todayOpen: 'Open Times Today',
  openCountComment:
    'The open times = browsers x {count}, if exceeded, which cannot be opened on the day and will be restored on the next day.',
  ordersNotPay: 'Unpaid Orders (USDT)',
  moneyUnit: '¥',
  unit: '',
  paymentOrder: 'Pay',
  paymentOrderDialog: 'Payment Order',
  paid: 'Payment Completed',
  wechatPay: 'Wechat Pay',
  balancePay: 'Balance',
  alipay: 'Alipay',
  rechargePay: 'Recharge',
  withdrawPay: 'Withdraw and recharge',
  time: 'Time',
  payMoney: 'Amount',
  payType: 'Payment Method',
  accountBalance: 'Balance',
  payRemark: 'Details',
  additionErrMsg: 'The free package does not support modify, please subscrip the paid package first.',
  paySuccess: 'Success',
  changeInfo: '<strong>Package</strong>(Original: Browsers {browserCount} Users {users})',
  pkgChangeLabel: 'Package',
  pkgChangeLabel2: 'Original: Browsers ',
  pkgChangeLabel3: 'Users ',
  pkgBrowsers: 'Browser Profiles: {count}',
  pkgUsers: 'Users: {count}',
  pkgUnit: '¥/Month',
  activateMonths: 'Cycle',
  useBalance: 'Balance',
  balanceAssets: 'Balance: {balance} ¥',
  paypalTips: 'Only Supports Overseas PayPal Account',
  totalBrowsers: 'Total Browsers: {count}',
  pkgExpired: 'Expiration Date: ',
  pkgOpenCountTips:
    'Note: The open times = {browsers} x {maxCount}, if exceeded, which cannot be opened on the day and will be restored on the next day.',
  shouldPay: 'Amount: ',
  pkgCommentsLabel1: 'Fee Calculation Formula:',
  pkgCommentsValue1: "(New Plan's Cost - Old Plan's Cost)/31 * Days Remaining",
  pkgCommentsLabel2: 'Note: ',
  pkgCommentsValue2: 'System will not refund the reduced package, please be cautious.',
  usersCommentsLabel: 'Increase Num x Cost/31 x Days Remaining',
  usersCommentsValue: 'System will not refund the reduced package or users, please be cautious.',
  changeConfirm: `
              <p class="text-red text-bold">After subscription changes:</p>
              <p class="text-red q-mt-sm">1. All user accounts will be disabled, please go to "Users" to enable them again!</p>
              <p class="text-red q-mt-sm">2. The system will not refund and effect immediately.</p>
              <p class="text-red q-mt-sm">3. Browser other than the number of packages will not be deleted, but will be forbiden to open.</p>
              <p class="q-mt-sm">Confirm to change?</p>
              `,
  activateSuccess: 'Success',
  changeSuccess: 'Success',
  calculateError: 'Amount calculation error, please try again.',
  couponDialog: 'Coupon',
  myCoupon: 'My Coupon',
  usedCoupon: 'Used',
  couponType: 'Type',
  couponStatus: 'Status',
  couponCode: 'Coupon Code',
  couponRemark: 'Remark',
  cashCoupon: 'Cash',
  discountCoupon: 'Discount',
  couponStatus0: 'Unused',
  couponStatus1: 'Used',
  couponValue: 'Coupon Value',
  couponValueFormat: '{value} ¥',
  couponCreatedTime: 'Release Date',
  couponExpired: 'Expired',
  couponState: 'Status',
  couponExpense: 'With Coupons',
  couponExpensePlaceholder: 'Coupon code',
  changeUsersDialog: 'Change Additional Users',
  additionalUsers: 'Increase Users',
  assignedUsers: 'Assigned ',
  totalUsers: 'Total ',
  increase: 'Increase',
  reduce: 'Reduce',
  userCost: 'Cost:',
  userCostUnit: ' ¥/User/Month',
  extraUsers: 'Additional Users: ',
  extraUsers2: ', ',
  reduceTips:
    'Note: When reducing users, if remaining users (total-used) is less than users to be reduced, all user accounts will be disabled after changed, please go to "Users" to enable them again.',
  totalExtraUsers: 'Total Users: ',
  usersRule1: 'Should greater than 0',
  usersRule2: 'Should less than 10000',
  usersRule3: 'The reduction cannot be greater than the current number of additional users',
  reduceUsersConfirm: 'System will not refund if users reduced and effect immediately, please be causious!<br/>Need to opreate or not?',
  changeUsersSuccess: 'Success',
  originalPrice: 'Original Price',
  finalPay: 'Now',
  cycleMonth: 'Month',
  cycleMonths: 'Months',
  confirmActivate: 'Confirm',
  confirmChange: 'Confirm',
  confirm: 'Confirm',
  orderCreatedTime: 'Order Time',
  orderMoney: 'Order Amount',
  orderState: 'Order Status',
  notPay: 'Unpaid',
  hasPaid: 'Paid',
  rechargePlaceholder: 'Please fill in the CNY amount',
  rechargeAmount: 'Amount',
  minAmount: 'Min Recharge Amount: {amount} ¥',
  rechargeRule1: 'Please fill in the amount which is a number',
  rechargeRule2: 'Min Recharge Amount ',
  rechargeRule3: 'The minimum unit of recharge amount is cents',
  pkgDetail: 'Package Details',
  extraAddUsers: 'Additional Users: {count}',
  renewCycle: 'Renewal Cycle',
  youhui: 'Discounted: ',
  renewExpired: 'Expiration Date After Renew: ',
  renewComments: '(Package Cost + Additional Users Cost) x Months',
  confirmRenew: 'Confirm',
  renewSuccess: 'Success',
  orderType: 'Order Type',
  orderType1: 'Recharge',
  orderType2: 'Subscribe',
  orderType3: 'Change Plan',
  orderType4: 'Renew',
  orderType8: 'Update Users',
  cycle: 'Cycle',
  activatePackage: 'Package {pkg}, Cycle: 1 Month',
  activatePackageMonths: 'Package {pkg}, Cycle: {cycle} Months',
  changePlan: 'Before: Package {before}, After: Package {after}',
  renewDetail: 'Package: {pkg}, Months: {months}',
  increaseUsers: 'Increase Users: {count}',
  reduceUsers: 'Reduce Users: {count}',
  autoRenew: 'Auto Renew',
  enableAutoRenew:
    'Are you sure to enable automatic renewal?<p class="q-mt-sm">Once enabled, if your account has sufficient balance, the system will automatically renew the 1-month package and deduct the corresponding balance 3 days before the expiration of your package!</p>',
  disableAutoRenew:
    'Are you sure to cancel automatic renewal?<p class="q-mt-sm">After canceling automatic renewal, the system will stop detecting whether the package expires and will not automatically renew the package for you!</p>',
  rate: 'Rate: ',
  bankCard: 'Bank Card',
  ratioRule1: 'Cannot be lower than the system default ratio.',
  ratioRule2: 'Cannot be higher than your commission ratio.',
  payWarning:
    '<p class="text-red">Note: Please use this payment method in a standardized manner. If any risky payment behavior such as fraudulent payment is found, your account will be permanently banned and cannot be unblocked.</p>',
  accountSummary: 'Account Summary',
  sheets: 'Sheets',
  recharge1: 'Recharge',
  rechargeTip: 'Recharge amount non-refundable. Share account balance with colleagues when purchasing/renewing equipment!',
  orderManagement: 'Order',
  export: 'Batch Export',
  orderNum: 'Order No',
  pleaseOrderNum: 'Enter the order number',
  pleaseSelectTime: 'Select the payment time',
  paymentTime: 'Payment Time',
  detail: 'Detail',
  paymentUser: 'Payment User',
  微信: 'WeChat',
  支付宝: 'Alipay',
  余额: 'Balance',
  后台充值: 'Backend Recharge',
  提现充值: 'Withdrawal and recharge',
  panpay: 'Panpay',
  虚拟币支付: 'Virtual currency payment',
  信用卡支付: 'Credit Cards Accepted',
  银行卡: 'Bank Card',
  orderDetail: 'Order Details',
  orderName: 'Order Name',
  number: 'Number',
  discountAmount: 'Discount Amount',
  actualPayment: 'Actual Payment',
  待支付: 'To Be Paid',
  生成中: 'Generating',
  已生效: 'Effective',
  生成失败: 'Failed',
  失效: 'Lose Efficacy',
  已支付: 'Paid',
  已取消: 'Canceled',
  交易异常: 'Error',
  支付失败: 'Failed',
  orderId: 'transaction Id',
  transactionType: 'transaction Type',
  balanceDetails: 'Balance Details',
  exportTip: 'Please select the time to export the data',
  tradeBalance: 'Trade Amount',
  afterBalance: 'balance',
  quickPayment: 'Quick payment',
  remainingTimes: 'Remaining Times',
  usedTimes: 'Used {arg} times',
  usedDate: 'Used Date',
  rechargeRebate: 'Recharge {amount1} CNY - {amount2} CNY',
  rechargeRebate1: 'Recharge {amount1} CNY - {amount2} CNY',
  rechargeRebate3: 'no rebate',
  rechargeRebate2: 'Rebate {rebate}',
  rechargeRebateTitle: 'Recharge Rebate Activities',
  rechargeRebateTitle1: ' Ends on {date}',
  discount: '% off',
  actualArrival: 'Actual Arrival:',
  discountPrice: 'Discount Price',
  originalPrice: 'Original Price',
  discountPrice1: 'Discount Price',
  chinIpbuyTip: 'IP is in Mainland China region, this payment method is not supported!',
  discountCoupons: 'Only monetary coupons are allowed, discount coupons are not permitted!',
  allowRenewal: 'Renew IP only',
  purchaseBuy: 'Purchase IP only',
  specialGiftTitle: 'Register and receive a special gift',
  specialGiftTip: 'Note: The validity period of the coupon is subject to the information on the coupon itself.',
  beenRefunded: ' This transaction amount has been refunded',
  cancelOrders: 'Cancel',
  useAction: 'Use',
  couponTip:
    'Note: Please ensure your order information is correct when using a coupon. Once a coupon is used, it will be consumed and cannot be refunded!',
  needRenewalNumTip:
    'The system has detected that {num} of your IP devices have auto-renewal enabled but have not yet completed the operation! Would you like to execute auto-renewal now?',
  executeRenewal: 'Execute Renewal',
  handRenewal: 'Manual Renewal',
  autoRenewTitle: 'Auto-Renewal Notification',
  autoRenewSuccessTip: 'Auto-renewal has been successfully completed. Please go to “Agents”'
}
